.onboarding-bg{
    background-image: url("/public/assets/images/onboarding-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100vh;
    padding: 40px;
    position: relative;
}

.onboarding-bg2{
    background-image: url("/public/assets/images/onboarding-bg2.png");
}

.onboarding-bg3{
    background-image: url("/public/assets/images/onboarding-bg3.png");
}

.onboarding-bg4{
    background-image: url("/public/assets/images/onboarding-bg4.png");
}

.onboarding-bg5{
    background-image: url("/public/assets/images/onboarding-bg5.png");
}


.millennials-bg{
    background-image: url("/public/assets/images/millenials-bg.png");
}

.onboarding-text{
    width: 100%;
    padding: 24px 60px;
    border-radius: 32px;
    background: linear-gradient(180deg, rgba(227, 221, 168, 0.6) 0%, rgba(125, 122, 93, 0.6) 100%);
    box-shadow: 0 4px 4px 0 #00000040;
    font-size: 32px;
    color:#fff;
    font-weight: 800;
    -webkit-text-stroke: 2px black;
    text-align: center;
}

.onboarding-actions{
    margin-top: 50px;
}

.onboarding-button{
    border-radius: 32px;
    background: linear-gradient(270deg, rgba(227, 221, 168, 0.6) 0%, rgba(125, 122, 93, 0.6) 100%);
    font-size: 28px;
    color:#fff;
    font-weight: 800;
    -webkit-text-stroke: 2px black;
    text-align: center;
    height: 68px !important;
    box-shadow: none;
    line-height: 1;
    width: 100%;
    text-wrap: pretty;
}

.onboarding-button:hover{
    background: linear-gradient(180deg, rgba(227, 221, 168, 0.8) 0%, rgba(125, 122, 93, 0.8) 100%) !important;
    border: 3px solid #000 !important;
    color:#fff !important;
}

.onboarding-button:active{
    background: linear-gradient(180deg, rgba(233, 227, 165, 0.8) 0%, rgba(255, 255, 255, 0.8) 100%) !important;
    border: 3px solid #fff !important;
    color: #000 !important;
    -webkit-text-stroke: unset;
}

.onboarding-button:hover:before{
    content: none
}

.arrow-button{
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background: linear-gradient(270deg, rgba(227, 221, 168, 0.6) 0%, rgba(125, 122, 93, 0.6) 100%);
    box-shadow: 0 4px 4px 0 #00000040;
    font-size: 65px;
    line-height: 1;
    padding: 0;
    text-align: center;
}

.arrow-button .anticon{
    border: 5px solid #000;
    border-radius: 50%;
    padding: 10px;
    color:#000;
}

.arrow-button:hover{
    color: #fff;
    background: linear-gradient(270deg, rgba(227, 221, 168, 0.6) 0%, rgba(125, 122, 93, 0.6) 100%);
}

.arrow-button:hover .anticon {
    border: 5px solid #fff;
    color:#fff;
}

.onboarding-text-dialogue{
    background: #fff;
    border-radius: 50%;
    max-width: 550px;
    position: absolute;
    top: 40px;
    left: 15%;
    text-align: center;
    font-weight: 600;
    padding: 30px 50px;
    font-size: 18px;
}

.dialogue-right{
    left: unset;
    right: 15%;
}

.onboarding-text-dialogue p{
    margin: 0;
}

.onboarding-image-left{
    position: absolute;
    left: 0;
    max-width: 100%;
    top: calc(50% - 150px);
    z-index: 0;
    width: 280px;
}

.onboarding-modal .modal-sidebar{
    background-color: #121212;
    color: #757575;
    padding: 28px;
    border-radius: 36px;
    height: 100%;
}

.onboarding-modal .ant-modal-content{
    padding: 0;
    border-radius: 36px;
    box-shadow: 0 13px 4px 4px #00000040;
    border: 1px solid #000000
}

.onboarding-modal .ant-modal-footer,
.onboarding-modal .ant-modal-close{
    display: none;
}

.combo-names p{
    font-size: 16px;
    font-weight: 800;
    line-height: 22.05px;
}

.combo-names p.current{
    color: #fff;
}

.combo-options{
    padding: 18px;
}

.combo-title{
    padding: 0.75em;
    font-size: 24px;
    font-weight: 800;
    text-align: center;
    color: #000;
    background-color: #D9D9D9;
}

.combo-selection{
    padding: 24px;
    margin-top: 1em;
    background-color: #D9D9D9;
}

.combo-selection h5{
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    color: #000;
    margin-top: 0;
    margin-bottom: 1em;
}
.combo-selection .ant-checkbox-wrapper span{
    font-weight: 800;
    font-size: 18px;
}

.info-button {
    position: relative;
}

.info-button img{
    position: absolute;
    width: 36px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}