@font-face {
    font-family: 'Gilroy';
    src: url('/public/assets/fonts/gilroy/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy';
    src: url('/public/assets/fonts/gilroy/Gilroy-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

*, *::before, *::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    line-height: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Gilroy', sans-serif;
}

.form-control {
    padding: 8px 22px;
    font-size: 14px;
    line-height: 1;
    color: #000;
    border-radius: 10px;
    border: 1px solid #BCBEC0;
    width: 100%;
}

.form-control.ant-input-affix-wrapper-focused {
    border: 1px solid #BCBEC0;
    box-shadow: 0 0 0 2px #BCBEC0;
}

.text-white {
    color: #fff;
}

a.text-white:hover {
    color: #000;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;

}

.fw-800 {
    font-weight: 800;
}

.d-none{
    display: none;
}

.text-center {
    text-align: center;
}

.text-danger {
    color: #ff4d4f;
}

.text-success {
    color: #00DEA3;
}

.w-100 {
    width: 100%;
}

.m-0 {
    margin: 0;
}

.p-0 {
    padding: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.ml-10 {
    margin-left: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-auto{
    margin-top: auto;
}

.gradient-border {
    position: relative;
    background-clip: padding-box !important;
    border: 3px solid transparent;
    z-index: 1;
}

.gradient-border:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -3px;
    border-radius: inherit;
    background: linear-gradient(135.59deg, rgba(227, 221, 168, 0.49) 1.28%, rgba(88, 130, 193, 0.11) 96.26%);
}

.d-block {
    display: block;
}

.d-flex{
    display: flex;
}

.justify-content-end{
    justify-content: end;
}

.cursor-pointer{
    cursor: pointer;
}

.text-primary{
    color: #5A55D2;
}

.text-end{
    text-align: end;
}

.btn-primary {
    background-color: #000;
    color: #fff;
    border-radius: 10px;
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    padding: 12px;
}

.btn-primary img {
    width: 16px;
    margin-bottom: -4px;
}

.ant-card {
    box-shadow: -10px 56px 140px 0 #8F9BBA33 !important;
    border-radius: 16px;
    height: 100%;
}

.ant-card .ant-card-head {
    border: none;
    font-size: 24px;
    font-weight: 800;
    line-height: 29.4px;
    letter-spacing: 0.016em;
    text-align: left;
    color: #00261C;
}

.ant-card .ant-card-head .ant-card-head-title span {
    font-size: 14px;
    font-weight: 800;
    line-height: 17.15px;
    letter-spacing: -0.02em;
    text-align: left;
}


.overview-chart .ant-card-body {
    padding: 24px 0 0;
}

.chart-options .ant-radio-group{
    display: flex;
    gap: 12px;
}

.chart-options .ant-radio-button-wrapper {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: -0.02em;
    text-align: center;
    color: #232927;
    border: 1px solid #AEB6CF;
    box-shadow: 0 15px 35px 0px #F2398526;
    border-radius: 12px;
}

.chart-options .ant-radio-button-wrapper:before{
    content: none;
}

.chart-options .ant-radio-button-wrapper.ant-radio-button-wrapper-checked,
.chart-options .ant-radio-button-wrapper:hover,
.chart-options .ant-radio-button-wrapper.ant-radio-button-wrapper-checked:hover{
    background: #00DEA3;
    box-shadow: 0 6px 16px 0 #00DEA30F;
    border-color: #00DEA3;
}

.chart-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*overflow: hidden;*/
    width: 100%;
    height: 300px;
}

.currency-card .ant-card-body{
    padding-bottom: 0;
}

.currency-card img{
    width: 100%;
}

.currency-symbol{
    color: #5A55D2;
    font-size: 24px;
    font-weight: 800;
    line-height: 29.4px;
    margin-top: 0;
    margin-bottom: 0.2em;
}

.currency-symbol span{
    color: #00140E;
    font-size: 16px;
    line-height: 19.4px;
}

.dashboard-amount{
    font-size: 34px;
    font-weight: 800;
    line-height: 41.65px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 0.3em;
}

.calendar-card{
    height: 100%;
}

.calendar-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar-header button{
    border: none;
    background-color: transparent;
    color: #757575;
    font-size: 18px;
}

.calendar-header p{
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin: 0;
    color: #424242;
}

.ant-picker-calendar .ant-picker-panel{
    border: none;
    margin-top: 10px;
}

.ant-picker-calendar .ant-picker-content thead th{
    color: #BDBDBD;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: 0.015em;
    text-align: center;
}

.ant-picker-calendar .ant-picker-content tbody td{
    font-size: 12px;
    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: 0.015em;
    text-align: center;
    border-radius: 50%;
    /*color: #424242;*/
}

.ant-picker-calendar .ant-picker-content tbody td.ant-picker-cell-today{
    color: #1E88E5;
}

.ant-picker-calendar .ant-picker-content tbody td.ant-picker-cell-today .ant-picker-cell-inner:before{
    border: none;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    bottom: -5px;
    left: calc(50% - 2px);
    top: unset;
    background: #1E88E5;
}

.ant-picker-calendar .ant-picker-content tbody td.ant-picker-cell-selected .ant-picker-cell-inner{
    border-radius: 50%;
    background: #FEF96F !important;
    color: #424242 !important;
}

.dashboard-table .ant-table-tbody >tr >th, .dashboard-table .ant-table-tbody >tr >td{
    border-bottom: none;
}

.dashboard-table .ant-table-tbody >tr >td{
    color: #707EAE;
    font-size: 16px;
    font-weight: 800;
    line-height: 19.6px;
    letter-spacing: 0.016em;
    text-align: left;
    text-transform: capitalize;
}

.references-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title{
    font-size: 12px;
    font-weight: 700;
    line-height: 15.6px;
    text-align: left;
    color: #757575;
}

.references-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description p{
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.06px;
    text-align: left;
}

.references-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-description p strong{
    color: #000;
}

.references-list .ant-list-item .ant-list-item-action a{
    color: #757575;
}
