.app-container{
    width: 100%;
    min-height: 100vh;

}

.main-content{
    background-color: #EFF3FD;
    padding: 20px;
}

.ant-layout-sider{
    background: #121212 !important;
    padding: 40px 16px;
}

.ant-layout-sider .ant-menu{
    background-color: transparent;

}

.ant-layout-sider .ant-menu-item{
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.92px;
    letter-spacing: 0.015em;
    padding: 10px 16px;
    height: auto;
}

.ant-layout-sider .ant-menu-item:hover:not(.active){
    color: #fff !important;
}

.ant-layout-sider .ant-menu-item .ant-menu-title-content{
    display: flex;
    align-items: center;
}
.ant-layout-sider .ant-menu-item i{
    font-size: 1.5em;
    margin-right: 16px;
}

.ant-layout-sider .ant-menu-item.active .ant-menu-title-content{
    background-color: #FEF96F;
    padding: 12px 16px;
    border-radius: 4px;
    color: #000;
    font-weight: 600;
}

.ant-layout-sider .ant-menu-item.active .ant-menu-title-content i{
    color: #997C7C;
}

.enter-simulation{
    background: linear-gradient(90deg, #FEF96F 1.31%, #1F4037 100%);
    box-shadow: 0 4px 4px 0 #00000040;
    border-radius: 7px;
    padding: 12px 16px;
    color: #1F4037;
}

.enter-simulation p {
    font-size: 13px;
    font-weight: 500;
    line-height: 16.9px;
    margin: 0;
}

.enter-simulation h2{
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: -0.02em;
    text-align: left;
    margin: 0;
}

.ant-layout-header{
    padding: 0;
    background: transparent;
    height: auto;
    line-height: 1;
    margin-bottom: 15px;
}

.ant-layout-header .user-profile{
    display: flex;
    align-items: center;
}

.ant-layout-header .user-profile img{
    width: 48px;
    height: 48px;
    object-fit: cover;
    border-radius: 12px;
}

.ant-layout-header .user-profile h6{
    font-size: 16px;
    font-weight: 800;
    line-height: 19.6px;
    letter-spacing: 0.016em;
    margin: 0;
}
