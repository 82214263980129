.auth-container{
    width: 100%;
    min-height: 100vh;
    background-image: url("/public/assets/images/auth-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-container > .ant-row{
    width: 100%;
}

.auth-container .auth-card{
    border-radius: 40px;
    padding: 20px 96px 40px;
    background: linear-gradient(180deg, rgba(227, 221, 168, 0.3) 0%, rgba(125, 122, 93, 0.3) 100%);
    box-shadow: 0 4px 4px 0 #00000040;
    backdrop-filter: blur(5px);
    margin: 20px 0;
}

.auth-logo{
    width: 60px;
    height: 60px;
    margin-bottom: 26px;
}

.auth-card h2{
    font-size: 38px;
    font-weight: 800;
    line-height: 47px;
    margin: 0 0 18px;
    color: #fff;
}

.ant-form-item-label label{
    color: #fff !important;
    font-weight: 800;
    font-size: 18px !important;
    line-height: 22px;
}

.ant-btn-submit{
    padding: 12px;
    border-radius: 10px;
    background: #000;
    font-size: 18px;
    color:#fff;
    font-weight: 800;
    line-height: 24px;
    text-align: center;
    width: 100%;
    height: initial;
    margin-top: 20px;
}

.ant-btn-submit:hover,
.ant-btn-submit:focus{
    background: #fff;
    color: #000;
}